import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { ForceGraph3D, ForceGraph2D } from "react-force-graph";
import SpriteText from "three-spritetext";
import * as THREE from "three";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";

const ExpandableGraph = ({
  graphData,
  setItem,
  setPrunedTree,
  prunedTree,
  containerRef,
  show,
  setShow,
}) => {
  const NODE_R = 3;
  const rootId = "InsuranceGIG";
  const extraRenderers = [new CSS2DRenderer()];

  useEffect(() => {
    // if (containerRef.current) {
    //   containerRef.current
    //     .d3Force("link")
    //     .distance((link) => {
    //       if (link.target.id.includes("workflowId")) {
    //         // return 80;
    //         return 80;
    //       } else {
    //         return 95;
    //       }
    //     })
    //     .strength((link) => 1);
    // }
  });

  // const nodesById = useMemo(() => {
  //   const nodesById = Object.fromEntries(
  //     graphData.nodes.map((node) => [node.id, node])
  //   );
  //   // link parent/children
  //   graphData.nodes.forEach((node) => {
  //     node.collapsed = node.id !== rootId;
  //     node.childLinks = [];
  //   });
  //   graphData.links.forEach((link) => {
  //     nodesById[link.source].childLinks.push(link);
  //   });

  //   return nodesById;
  // }, [graphData]);

  // const getPrunedTree = useCallback(() => {
  //   const visibleNodes = [];
  //   const visibleLinks = [];
  //   (function traverseTree(node = nodesById[rootId]) {
  //     visibleNodes.push(node);
  //     if (node.collapsed) return;
  //     visibleLinks.push(...node.childLinks);
  //     node.childLinks
  //       .map((link) =>
  //         typeof link.target === "object" ? link.target : nodesById[link.target]
  //       ) // get child node
  //       .forEach(traverseTree);
  //   })();

  //   return { nodes: visibleNodes, links: visibleLinks };
  // }, [nodesById]);

  // const handleNodeClick = useCallback((node) => {
  //   // let prunedTree = getPrunedTree();
  //   node.collapsed = !node.collapsed;
  //   let ans = prunedTree.nodes.filter(
  //     (n) => n.id.includes("workflowId") && n.collapsed != true
  //   );
  //   if (ans.length == 0) {
  //     prunedTree.nodes = prunedTree.nodes.map((n) => {
  //       if (n.id.includes("workflowId")) {
  //         n.renderColor = n.color;
  //         return n;
  //       } else {
  //         return n;
  //       }
  //     });
  //   }
  //   if (node.id.includes("workflowId")) {
  //     if (!node.collapsed) {
  //       prunedTree.nodes = prunedTree.nodes.map((n) => {
  //         if (n.id.includes("workflowId")) {
  //           if (!n.collapsed) {
  //             n.height = 40;
  //             n.width = 25;
  //             n.renderColor = n.color;
  //             return n;
  //           } else {
  //             n.renderColor = n.alternativeColor;
  //             return n;
  //           }
  //         } else {
  //           return n;
  //         }
  //       });
  //     }
  //   }

  //   if (node.id.includes("workflowImplementation")) {
  //     let ansImp = prunedTree.nodes.filter(
  //       (n) =>
  //         n.id.includes("workflowImplementation") &&
  //         n.id.workflowId == node.workflowId &&
  //         n.collapsed != true
  //     );
  //     if (ansImp.length == 0) {
  //       prunedTree.nodes = prunedTree.nodes.map((n) => {
  //         if (n.id.includes("workflowImplementation")) {
  //           n.renderColor = n.color;
  //           return n;
  //         } else {
  //           return n;
  //         }
  //       });
  //     }
  //   }

  //   if (node.id.includes("workflowImplementation")) {
  //     if (!node.collapsed) {
  //       prunedTree.nodes = prunedTree.nodes.map((n) => {
  //         if (n.id.includes("workflowImplementation")) {
  //           if (!n.collapsed) {
  //             n.renderColor = n.color;
  //             return n;
  //           } else {
  //             if (n.workflowId == node.workflowId) {
  //               n.renderColor = n.alternativeColor;
  //             } else {
  //               n.renderColor = n.color;
  //             }
  //             return n;
  //           }
  //         } else {
  //           return n;
  //         }
  //       });
  //     }
  //   }

  //   setPrunedTree(getPrunedTree());
  // }, []);

  useEffect(() => {
    if (Object.keys(graphData).length != 0) {
      // setPrunedTree(getPrunedTree());
    }
  }, []);

  const renderNode = (node) => {
    let imgPath;
    if (node.img) {
      if (node.id.includes("i-")) {
        imgPath = `imgs/${node.img}`;
      } else {
        imgPath = `imgs/${node.renderImg}`;
      }
      const loader = new THREE.TextureLoader();
      const imgTexture = loader.load(imgPath);
      const material = new THREE.SpriteMaterial({ map: imgTexture });
      const sprite = new THREE.Sprite(material);
      sprite.scale.set(node.width, node.height);
      return sprite;
    } else if (node.imgUrl) {
      const nodeEle = document.createElement("div");
      const imgEle = document.createElement("img");
      const p = document.createElement("span");
      nodeEle.style.padding = "20px";
      imgEle.src = node.imgUrl;
      imgEle.style.height = "20px";
      imgEle.style.width = "20px";
      imgEle.style.borderRadius = "5px";
      p.innerText = node.name;
      p.style.fontSize = "18px";
      p.style.marginLeft = "10px";
      nodeEle.appendChild(imgEle);
      nodeEle.appendChild(p);
      nodeEle.className = "child-nodes-div";
      nodeEle.style.borderColor = node.borderColor;
      // nodeEl.className = 'node-label';
      return new CSS2DObject(nodeEle);
    }
  };
  function genRandomTree(N = 300, reverse = false) {
    return {
      nodes: [...Array(N).keys()].map((i) => ({ id: i })),
      links: [...Array(N).keys()]
        .filter((id) => id)
        .map((id) => ({
          [reverse ? "target" : "source"]: id,
          [reverse ? "source" : "target"]: Math.round(Math.random() * (id - 1)),
        })),
    };
  }

  function nodePaint({ id, x, y }, color, ctx) {
    ctx.fillStyle = color;
    [
      () => {
        ctx.fillRect(x - 6, y - 4, 12, 8);
      }, // rectangle
      () => {
        ctx.beginPath();
        ctx.moveTo(x, y - 5);
        ctx.lineTo(x - 5, y + 5);
        ctx.lineTo(x + 5, y + 5);
        ctx.fill();
      }, // triangle
      () => {
        ctx.beginPath();
        ctx.arc(x, y, 5, 0, 2 * Math.PI, false);
        ctx.fill();
      }, // circle
      () => {
        ctx.font = "10px Sans-Serif";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText("Text", x, y);
      }, // text
    ][2 % 4]();
  }

  // gen a number persistent color from around the palette
  const getColor = (n) =>
    "#" + ((n * 1234567) % Math.pow(2, 24)).toString(16).padStart(6, "0");
  console.log("||||+++");
  let xData = genRandomTree(100);
  console.log(xData);
  console.log(graphData);


  return (
    <ForceGraph2D
      // width="300"
      // height="300"
      graphData={graphData}
      nodeLabel={(node) =>
        `<span style="color: #fff">${node.title || node.name || node.id}</span>`
      }
      onNodeHover={(node) => {
        if (node != null) {
          setItem(node);
          setShow(true);
        }
      }}
      // nodeColor={(node)=> "#fff"}
      nodeCanvasObject={(node, ctx, globalScale) => {
        if (node.id.includes("i-")) {
          const fontSize = 12 / globalScale;
          var img = new Image();
          img.src = `imgs/${node.img}`;
          ctx.drawImage(
            img,
            node.x - fontSize - 5,
            node.y - fontSize - 5,
            20,
            20
          );
          // var label = node.name || node.Title;
          // const fontSize = 12/globalScale;
          // ctx.font = `${fontSize}px Sans-Serif`;
          // const textWidth = ctx.measureText(label).width;
          // const bckgDimensions = [textWidth, fontSize].map(n => n + fontSize * 0.2); // some padding

          // ctx.fillStyle = 'rgba(255, 255, 255, 0.8)';
          // ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

          // ctx.textAlign = 'center';
          // ctx.textBaseline = 'middle';
          // ctx.fillStyle = "green";
          // ctx.fillText(label, node.x, node.y);

          // node.__bckgDimensions = bckgDimensions;
        } else {
          var label = node.name || node.Title;
          const fontSize = 10 / globalScale;
          ctx.font = `${fontSize}px Sans-Serif`;
          const textWidth = ctx.measureText(label).width;
          const bckgDimensions = [textWidth, fontSize].map(
            (n) => n + fontSize * 0.2
          ); // some padding

          // ctx.fillStyle = 'rgba(255, 255, 255, 0.03)';
          // ctx.fillRect(node.x - bckgDimensions[0] / 2, node.y - bckgDimensions[1] / 2, ...bckgDimensions);

          ctx.strokeStyle = node.borderColor;
          ctx.strokeRect(
            node.x - bckgDimensions[0] / 2,
            node.y - bckgDimensions[1] / 2,
            ...bckgDimensions
          );

          // ctx.borderColor = 'red';
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = "white";
          ctx.fillText(label, node.x, node.y);

          node.__bckgDimensions = bckgDimensions;
        }

        // to re-use in nodePointerAreaPaint
      }}
      // nodeCanvasObject={(node, ctx) => nodePaint(node, getColor(2), ctx)}
      // nodePointerAreaPaint={nodePaint}
      linkColor={(link) => "#fff"}
      linkWidth={1}
    />
    // <ForceGraph2D
    //   ref={containerRef}
    //   // extraRenderers={extraRenderers}
    //   // dagMode="zin"
    //   backgroundColor="rgba(211, 158, 255,0)"
    //   graphData={graphData}
    //   linkDirectionalParticles={2}
    //   nodeLabel={(node) =>
    //     `<span style="color: #fff">${node.title || node.name || node.id}</span>`
    //   }
    //   linkWidth={1.8}
    //   // d3VelocityDecay={0.3}
    //   // linkResolution={20}
    //   // linkDirectionalParticleSpeed={0.08}
    //   linkDirectionalParticleColor={(link) => "rgba(255, 255, 255,1)"}
    //   linkWidth={1.8}
    //   // dagLevelDistance={120}
    //   // d3VelocityDecay={0.3}
    //   // linkDirectionalParticles={(link) => {
    //   //   return link.target.linkDirectionalParticles || 0;
    //   // }}
    //   // linkDirectionalParticleWidth={(link) => {
    //   //   return link.target.linkDirectionalParticleWidth || 0.5;
    //   // }}
    //   // d3VelocityDecay={0.3}
    //   // linkPositionUpdate={(sprite, { start, end }) => {
    //   //   const middlePos = Object.assign(
    //   //     ...["x", "y", "z"].map((c) => ({
    //   //       [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
    //   //     }))
    //   //   );

    //   //   // Position sprite
    //   //   Object.assign(sprite.position, middlePos);
    //   // }}
    //   minZoom={300}
    //   onNodeDragEnd={(node) => {
    //     node.fx = node.x;
    //     node.fy = node.y;
    //     node.fz = node.z;
    //   }}
    //   nodeRelSize={NODE_R}
    //   autoPauseRedraw={false}
    //   showNavInfo={true}
    //   nodeResolution={20}
    //   nodeOpacity={0.75}
    //   nodeVal={(node) => 8 + node.value || 8}
    //   // linkDirectionalParticleSpeed={0.08}
    //   nodeColor={(node) => {
    //     return node.renderColor;
    //   }}
    //   onNodeDragEnd={(node) => {
    //     node.fx = node.x;
    //     node.fy = node.y;
    //     node.fz = node.z;
    //   }}
    //   // onNodeHover={(node) => {
    //   //   if (node != null) {
    //   //     setItem(node);
    //   //     setShow(true);
    //   //   }
    //   // }}
    //   // onNodeClick={handleNodeClick}
    //   // linkThreeObjectExtend={true}
    //   // linkThreeObject={(link) => {
    //   //   // extend link with text sprite
    //   //   const sprite = new SpriteText(``);

    //   //   sprite.color = "white";
    //   //   // if (!link.source.collapsed && link.source.id !== "m-1") {
    //   //   //   sprite.color = "white";
    //   //   // }
    //   //   sprite.textHeight = 1.5;
    //   //   return sprite;
    //   // }}
    //   // linkResolution={20}
    //   // linkDirectionalParticleSpeed={0.08}
    //   // linkWidth={1.8}
    //   // dagLevelDistance={120}
    //   // d3VelocityDecay={0.3}
    //   // linkDirectionalParticles={(link) => {
    //   //   return link.target.linkDirectionalParticles || 0;
    //   // }}
    //   // linkDirectionalParticleWidth={(link) => {
    //   //   return link.target.linkDirectionalParticleWidth || 0.5;
    //   // }}
    //   // d3VelocityDecay={0.3}
    //   // nodeThreeObjectExtend={true}
    //   // nodeThreeObject={(node, canvasContext, scale) =>
    //   //   renderNode(node, canvasContext, scale)
    //   // }
    //   // linkPositionUpdate={(sprite, { start, end }) => {
    //   //   const middlePos = Object.assign(
    //   //     ...["x", "y", "z"].map((c) => ({
    //   //       [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
    //   //     }))
    //   //   );

    //   //   // Position sprite
    //   //   Object.assign(sprite.position, middlePos);
    //   // }}
    // />
  );
};

export default ExpandableGraph;
