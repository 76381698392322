import React, {
  useEffect,
  useCallback,
  useMemo, useState,
} from "react";
import { ForceGraph3D } from "react-force-graph";
import SpriteText from "three-spritetext";
import * as THREE from "three";
import {
  CSS2DRenderer,
  CSS2DObject,
} from "three/examples/jsm/renderers/CSS2DRenderer.js";
import {GraphData} from "../../data/DataService";

const ExpandableGraphEl = ({
  graphData,
  setItem,
  setPrunedTree,
  prunedTree,
  containerRef,
  setShow,
  isFiltered
}) => {
  const NODE_R = 3;
  const rootId = "InsuranceGIG";
  const extraRenderers = [new CSS2DRenderer()];
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (containerRef?.current) {
      containerRef.current
        .d3Force("link")
        .distance((link) => {
          if (link.target.id.includes("workflowId")) {
            return 80;
          } else {
            return 35;
          }
        })
        .strength((link) => 1);
    }
  });

  useEffect(() => {
    if (initialized || !containerRef?.current?.onEngineStop) {
      return;
    }

    containerRef.current.zoomToFit(400, 4);
    setInitialized(true);
    console.log("Zoom in");
  }, [initialized, containerRef.current])

  const nodesById = useMemo(() => {
    const nodesById = Object.fromEntries(
        graphData.nodes.map(node => [node.id, node]));

    // link parent/children
    graphData.nodes.forEach(node => {
      node.collapsed = node.id !== rootId && !isFiltered; //&& !node.id.startsWith("workflowId");
      node.childLinks = [];
    });
    graphData.links.forEach(link => nodesById[link.source].childLinks.push(link));

    return nodesById;
  }, [graphData]);

  const getPrunedTree = useCallback(() => {
    const visibleNodes = [];
    const visibleLinks = [];
    (function traverseTree(node = nodesById[rootId]) {
      visibleNodes.push(node);
      if (node.collapsed) return;
      visibleLinks.push(...node.childLinks);

      if (node.childLinks) {
        node.childLinks
            .map(link => ((typeof link.target) === 'object')
                ? link.target
                : nodesById[link.target]) // get child node
            .forEach(traverseTree);
      }
    })();

    return { nodes: visibleNodes, links: visibleLinks };
  }, [nodesById]);

  const handleNodeClick = useCallback(node => {
    node.collapsed = !node.collapsed; // toggle collapse state
    setPrunedTree(getPrunedTree())
  }, []);

  useEffect(() => {
    if (Object.keys(graphData).length !== 0) {
      setPrunedTree(getPrunedTree());
    }
  }, [graphData]);

  const renderNode = (node) => {
    let imgPath;
    if (node.img) {
      // Root node
      if (node.id === "InsuranceGIG") {
        imgPath = `imgs/${node.img}`;
      } else {
        imgPath = `imgs/${node.renderImg}`;
      }
      const loader = new THREE.TextureLoader();
      const imgTexture = loader.load(imgPath);
      const material = new THREE.SpriteMaterial({ map: imgTexture });
      const sprite = new THREE.Sprite(material);
      sprite.scale.set(node.width, node.height);
      return sprite;
    } else if (node.imgUrl) {
      // Buyer / Seller Node
      const nodeEle = document.createElement("div");
      const imgEle = document.createElement("img");
      const p = document.createElement("span");
      nodeEle.style.padding = "2px 4px 2px 0";
      imgEle.src = node.imgUrl;
      imgEle.style.maxHeight = "30px";
      imgEle.style.maxWidth = "30px";
      imgEle.style.borderRadius = "4px";
      p.innerText = node.name;
      p.style.fontSize = "12px";
      p.style.marginLeft = "10px";
      nodeEle.appendChild(imgEle);
      nodeEle.appendChild(p);
      nodeEle.className = "child-nodes-div";
      nodeEle.style.borderColor = node.borderColor;
      //nodeEle.className = 'node-label';
      return new CSS2DObject(nodeEle);
    } else {
      // Any other node
      const nodeEl = document.createElement('div');
      nodeEl.textContent = node.title || node.name || "";
      nodeEl.style.color = node.color;
      nodeEl.className = 'node-label';
      return new CSS2DObject(nodeEl);
    }
  };

  return (
    <ForceGraph3D
      ref={containerRef}
      extraRenderers={extraRenderers}
      dagMode="zin"
      backgroundColor="rgba(211, 158, 255,0)"
      graphData={prunedTree}
      nodeLabel={(node) => null
      }
      linkWidth={1.8}
      d3VelocityDecay={0.3}
       linkDirectionalParticleColor={(link) =>
       "rgba(255, 255, 255,1)"
      }
      linkDirectionalParticles={0}
      linkDirectionalParticleWidth={(link) => {
        return link.target.linkDirectionalParticleWidth || 0.5;
      }}
      linkPositionUpdate={(sprite, { start, end }) => {
        const middlePos = Object.assign(
          ...["x", "y", "z"].map((c) => ({
            [c]: start[c] + (end[c] - start[c]) / 2, // calc middle point
          }))
        );
        // Position sprite
        Object.assign(sprite.position, middlePos);
      }}
      minZoom={300}
      onNodeDragEnd={(node) => {
        node.fx = node.x;
        node.fy = node.y;
        node.fz = node.z;
      }}
      nodeRelSize={NODE_R}
      autoPauseRedraw={true}
      showNavInfo={false}
      nodeResolution={20}
      nodeOpacity={0.75}
      nodeVal={(node) => 8 + node.value || 8}
      nodeColor={(node) => {
        return node.renderColor;
      }}
      onNodeHover={(node) => {
        if (node != null) {
          setItem(node);
          setShow(true);
        }
      }}
      onNodeClick={handleNodeClick}
      linkThreeObjectExtend={true}
      linkThreeObject={(link) => {
        // extend link with text sprite
        const sprite = new SpriteText(``);

        sprite.color = "lightgrey";
        if (!link.source.collapsed && link.source.id !== "InsuranceGIG") {
          sprite.color = "white";
        }
        sprite.textHeight = 1.5;
        return sprite;
      }}
      linkResolution={20}
      linkDirectionalParticleSpeed={0.3}
      nodeThreeObjectExtend={true}
      nodeThreeObject={(node, canvasContext, scale) =>
        renderNode(node, canvasContext, scale)
      }
    />
  );
};

const ExpandableGraph = React.memo(ExpandableGraphEl);

export default ExpandableGraph;
