export interface NodeColors {
    color: string,
    alternativeColor: string,
    renderColor: string,
    borderColor?: string,
    strokeColor?: string
}

export const workflowImplementationColors: NodeColors = {
    color: "rgba(255, 179, 215, 1.0)",
    alternativeColor: "rgba(255, 179, 215, 0.5)",
    renderColor: "rgba(255, 179, 215, 1.0)"
}

export const emptyWorkflowColors: NodeColors = {
    color: "rgba(120, 81, 150, 1.0)",
    alternativeColor: "rgba(120, 81, 150, 0.3)",
    renderColor: "rgba(120, 81, 150, 1.0)"
}

export const workflowWithChildColor: NodeColors = {
    color: "rgba(139, 23, 231, 1.0)",
    alternativeColor: "rgba(139, 23, 231, 0.7)",
    renderColor: "rgba(139, 23, 231, 1.0)"
}

export const sellerColors: NodeColors = {
    color: "rgba(119, 1, 216, 0.0)",
    renderColor: "rgba(255, 0, 0, 0)",
    alternativeColor: "rgba(255, 0, 0, 0)",
    borderColor: "red"
}

export const buyerColors: NodeColors = {
    color: "rgba(119, 1, 216,0.0)",
    renderColor: "rgba(0, 255, 0, 0)",
    alternativeColor: "rgba(0, 255, 0, 0)",
    borderColor: "green"
}

export const rootNodeColors: NodeColors = {
    color: "rgba(119, 1, 216,0.0)",
    renderColor: "rgba(255, 0, 0, 0.0)",
    alternativeColor: "rgba(255, 0, 0,0.0)",
    strokeColor: "white",
}